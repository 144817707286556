@import '@/core/tamagoshi/scss';
@import '@/domains/productDiscovery/HeaderLeonidas/styles/commons';

.overlay {
  z-index: $headerLeonidasZIndexOverlay;

  &[data-overlay='overlay-menu'],
  &[data-overlay='overlay-promos'] {
    position: fixed;
    background-color: $n800;
    opacity: 0.24;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &[data-overlay='overlay-search'] {
    position: fixed;
    background-color: $n800;
    opacity: 0.24;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@import '@/core/tamagoshi/scss/colors';
@import '@/core/tamagoshi/scss/zIndexes';

.bar {
  position: fixed;
  z-index: $zIndexClickTriggeredContent;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: $ba500;
  &.b2b {
    background-color: $v500;
  }
}

.peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow:
    0 0 10px $ba500,
    0 0 5px $ba500;
  opacity: 1;

  transform: rotate(3deg) translate(0, -4px);
  &.b2b {
    box-shadow:
      0 0 10px $v500,
      0 0 5px $v500;
  }
}

@import '@/core/tamagoshi/scss';
@import '../../scss/variables';

.root {
  display: flex;
  align-items: center;
  padding: $spacingXXs calc(#{$spacingXXs} + #{$suggestionsBlockSpacing});
  height: 28px;
  width: 100%;
  justify-content: space-between;
  @include size-above('md') {
    padding: 0;
  }

  .title {
    @include text('body2', 'bold');
  }
}

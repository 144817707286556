@import '@/core/tamagoshi/scss';
@import '../../scss/variables';
@import '@/core/tamagoshiv2/tokens/colors/_palette';
@import '@/domains/productDiscovery/HeaderLeonidas/styles/commons';

.root {
  position: relative;
  width: 100%;
  padding: 0 $suggestionsBlockSpacing;
}

.group {
  margin: $spacingXs 0 $spacingSm;
  display: inline-flex;
  flex-direction: row;

  @include size-below('md') {
    margin-top: $spacingMd;
    margin-bottom: 0;
  }

  @include size-above('md') {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: $spacingMd;
    column-gap: $spacingMd;
    margin: $spacingXs 0 0;
  }

  .product {
    display: block;
    height: $productSuggestionsProductCardHeight;
    width: $productSuggestionsProductCardWidth;

    &:not(:first-child) {
      margin-left: $spacingXs;
      @include size-above('md') {
        margin-left: 0;
      }
    }
  }
}

.buttonWrapper {
  @include size-below('md') {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: $spacingLg $spacingMd;
    background-color: $Sq000;
  }
}

.seeMoreButton {
  height: 40px;

  @include size-above('md') {
    margin-top: $spacingMd;
  }
}

@import '@/core/tamagoshi/scss';
@import '../../scss/variables';

.root {
  padding: 0 $suggestionsBlockSpacing;

  .title {
    height: $productSuggestionsTitleHeight;
  }

  .message {
    background-color: $n100;
    text-align: center;
    height: $productSuggestionsProductCardHeight;
    width: 100%;
    border-radius: 4px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: $spacingMd 0 $spacingSm;
    padding: 0 $spacingXl;

    .text {
      color: $n700;
      @include text('body2');
    }
  }
}

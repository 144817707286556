@use 'sass:math';
@import '@/core/tamagoshi/scss';
@import '../../styles/commons';

$menuDesktopWidth: 284px;
$menuTop: 64px;
$menuBannerHeight: 40px;
$menuItemBorderRadius: $spacingXXs;
$menuCategoryPanelWidth: 624px;

.container {
  z-index: $headerLeonidasZIndexMenu;

  &[data-visible='false'] {
    display: none;
  }

  @include size-above('md') {
    max-width: 964px;

    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
  }
}

/****************************************************************************************************
 * .header: Menu top section
 ****************************************************************************************************/

.header {
  @include size-below('md') {
    position: fixed;
    top: 8px;
    right: 0;
    left: 0;
    height: 56px;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    color: $n0;
    background-color: $b700;
    border-bottom: 1px solid $n300;
  }

  @include size-above('md') {
    position: relative;
  }
}

.headerTitle {
  @include text('body1', 'semiBold');

  @include size-above('md') {
    display: none;
  }
}

.headerBackButton {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  @include size-above('md') {
    display: none;
  }
}

.headerCloseButton {
  @include size-below('md') {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }

  @include size-above('md') {
    position: absolute;
    top: $spacingXs;
    left: $spacingLg + $menuDesktopWidth + $spacingMd + $spacingMd + 15px;

    .container[data-visible='true'][data-category-expanded='true'] & {
      left: $spacingLg + $menuDesktopWidth + $spacingMd + $spacingMd + 10px +
        $menuCategoryPanelWidth;
    }
  }
}

/****************************************************************************************************
 * .body: Menu section containing items, families and categories
 ****************************************************************************************************/

.body {
  @include size-below('md') {
    position: fixed;
    top: $menuTop;
    left: 0;
    bottom: 0;

    background-color: $n0;

    right: 0;

    .container[data-family-expanded='true'] & {
      transform: translateX(-100vw);
    }

    &:after {
      content: '';
      position: absolute;
      top: calc(100% - 1px);
      left: 0;
      right: 0;
      height: 25vh;
      background-color: $n0;
      pointer-events: none;
    }
  }

  @include size-above('md') {
    position: absolute;
    top: 1px;
    left: $spacingMd;
    height: calc(100vh - 166px);

    div[role='alert'] + header & {
      height: calc(100vh - 220px);
    }
  }

  @include size-above('lg') {
    left: $spacingMd;
  }
}

/****************************************************************************************************
 * .categoriesTitle: Menu title for the category section
 ****************************************************************************************************/

.menuSeparator {
  padding: $spacingXs $spacingMd $spacingXXs $spacingMd;
  border-top: 1px solid $n300;
  background-color: $n200;
  color: $n700;
  @include text('body2', 'bold');

  @include size-above('md') {
    margin-bottom: $spacingXs;
    margin-top: $spacingXs;
  }
}

.linksTitle {
  @include size-above('md') {
    display: none;
  }
}

.categoriesTitle {
  @include size-above('md') {
    padding: 0;

    span {
      display: none;
    }
  }
}

/****************************************************************************************************
 * .menuItem: Menu item containing extra items
 ****************************************************************************************************/

.menuItem {
  padding: 0;

  @include size-above('md') {
    width: $menuDesktopWidth;
  }
}

.menuItemLink.menuItemLink {
  color: $b500;
}

/****************************************************************************************************
 * .menuFamilies: Menu section containing families
 ****************************************************************************************************/

.menuFamilies {
  max-height: 100%;
  overflow-x: visible;
  overflow-y: auto;

  margin: 0;
  padding: 0;
  list-style: none;

  @include size-above('md') {
    padding: $spacingMd;
    max-height: 100%;

    background-color: $n0;
    border-bottom-left-radius: $spacingXs;
    border-bottom-right-radius: $spacingXs;

    .container[data-expanded='true'] & {
      height: 100%;

      background: $n0 linear-gradient(to bottom, $n300, $n300) no-repeat right
        bottom / 1px calc(100% - #{$spacingMd});
      border-bottom-right-radius: 0;
      border-right-color: $n0;
    }

    .container[data-family-expanded='true'] & {
      overflow-y: hidden;
    }
  }
}

.menuFamilyItem {
  padding: 0;

  @include size-above('md') {
    width: $menuDesktopWidth;
  }
}

.menuFamilyItemLink {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;

  width: 100%;
  min-height: $heightLg;

  margin: 0;
  padding: $spacingXXs $spacingSm $spacingXXs $spacingMd;

  font-family: $fontFamily;
  @include text('body2', 'semiBold');
  text-decoration: none;
  cursor: pointer;

  color: $n800;
  background-color: $n0;
  border: 0;
  border-radius: $menuItemBorderRadius;

  transition:
    color 0.15s ease-out,
    background-color 0.15s ease-out,
    box-shadow 0.25s ease-out;

  &:hover {
    background-color: $b100;
    cursor: pointer;
  }

  &:active {
    background-color: $b200;
  }

  &:focus {
    box-shadow: inset 0 0 0 1px #12bbff; // stylelint-disable-line color-no-hex
  }

  &:disabled {
    color: $n600;
    background-color: $n300;
  }

  @include size-below('md') {
    background-image: linear-gradient(to right, $n300, $n300);
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 100% 1px;
    border-radius: 0;

    .menuCategoryItem[data-expanded='true'] & {
      @include text('body2', 'bold');

      border-bottom: 1px solid $n0;
    }
  }

  @include size-above('md') {
    padding-left: $spacingXs;
    @include text('small', 'semiBold');
  }

  span {
    flex: 1;
  }
}

.menuFamilyItemLinkIcon {
  margin-right: $spacingXs;
}

.menuFamilyItemLinkArrow {
  display: block;
  width: $spacingLg;
  height: $spacingLg;
  margin-left: $spacingXs;

  transition: transform 0.25s ease-out;

  @include size-above('md') {
    width: $spacingMd;
    height: $spacingMd;

    .menuFamilyItemLink:not(:hover, :active, :disabled) & {
      color: $n700;
    }

    .menuFamilyItemLink:hover & {
      transform: translateX($spacingXXs);
    }
  }
}

/****************************************************************************************************
 * .menuFamily: Menu section containing family banner and categories
 ****************************************************************************************************/

.menuFamilyBanner {
  text-decoration: none;
  color: $b500;

  display: flex;
  align-items: center;

  margin: 14px $spacingMd 0;
  height: 50px;
  border-bottom: 1px solid $n400;
  padding: 10px 0 18px;

  @include size-below('md') {
    margin: $spacingSm 0 0;
    padding: 10px $spacingMd 18px $spacingXXs;
  }

  @include size-above('md') {
    width: $menuDesktopWidth;
  }
}

.menuFamilyBannerPrevious {
  background-color: $n0;
  border: none;

  @include size-above('md') {
    max-width: 215px;
  }

  &:hover {
    cursor: pointer;
  }
}

.menuFamilyBannerTitleName {
  display: block;
  color: $b500;
  @include text('body2', 'bold');
}

.menuFamilyBannerTitleNameArrow {
  display: inline-block;
  vertical-align: middle;
  width: $spacingLg;
  height: $spacingLg;
  margin-right: $spacingXs;

  transition: transform 0.25s ease-out;

  .menuFamilyBannerPrevious:hover & {
    transform: translateX(-$spacingXXs);
  }
}

.menuFamilySeeAllLink {
  font-family: $fontFamily;
  @include text('small');
  color: $b500;
  text-decoration: none;
  margin-left: auto;
}

/****************************************************************************************************
 * .menuCategories: Menu section containing categories
 ****************************************************************************************************/

.menuCategories {
  margin: 0;
  padding: 0;
  list-style: none;

  background-color: $n0;

  @include size-below('md') {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    width: 100%;

    overflow-x: hidden;
    overflow-y: hidden;
  }

  @include size-above('md') {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    border-right: 1px solid $n400;
    border-bottom-left-radius: $spacingXs;

    .container[data-family-expanded='true'][data-category-expanded='false'] & {
      border-bottom-right-radius: $spacingXs;
    }
  }

  &[aria-hidden='true'] {
    display: none;
  }
}

.menuCategoryList {
  overflow-y: auto;
  height: calc(100% - 64px);
  padding: $spacingXs 0 $spacingXs $spacingMd;

  @include size-below('md') {
    padding: 0;
  }
}

.menuCategoryItem {
  display: block;
  background-color: $n0;

  @include size-below('md') {
    &[data-expanded='true'] {
      background-color: $b100;
    }
  }

  @include size-above('md') {
    width: $menuDesktopWidth;
  }
}

.menuCategoryItemLink {
  @include size-below('md') {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;

    margin: 0;
    padding: $spacingXXs $spacingSm $spacingXXs $spacingMd;

    width: 100%;
    min-height: $heightLg;

    font-family: $fontFamily;
    @include text('body2', 'semiBold');

    color: $n800;
    text-decoration: none;
    border: 0;
    background: $n0 linear-gradient(to right, $n300, $n300) no-repeat right
      bottom / calc(100% - #{$spacingXs}) 1px;

    cursor: pointer;
  }

  @include size-above('md') {
    display: none;
  }
}

.menuCategoryItemLinkArrow {
  display: block;
  width: $spacingLg;
  height: $spacingLg;
  margin-left: $spacingXs;

  transition: transform 0.25s ease-in-out;

  @include size-below('md') {
    transform: rotate(90deg);

    .menuCategoryItem[data-expanded='true'] & {
      transform: rotate(-90deg);
    }
  }

  @include size-above('md') {
    width: $spacingMd;
    height: $spacingMd;

    .menuFamilyItemLink:hover & {
      transform: translateX($spacingXXs);
    }
  }
}

.menuCategoryItemLinkLabel {
  flex: 1;
}

.menuCategoryBanner {
  display: block;

  @include size-above('md') {
    flex: 0 0 100%;
    min-width: 100%;
    width: 100%;
  }
}

a.menuCategoryBannerLink {
  font-family: $fontFamily;
  @include text('body2', 'bold');

  span:first-child {
    margin-right: 0.31em;
  }

  @include size-above('md') {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    margin: 0 $spacingMd;
    padding: $spacingMd 0;

    @include text('body2', 'semiBold');

    color: $n800;
    border: 0;
    background: none;
    text-decoration: none;

    span:first-child {
      display: none;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

/****************************************************************************************************
 * .menuSubCategories: Menu section containing subCategories
 ****************************************************************************************************/

.menuSubCategoriesLinks {
  display: block;
  margin: 0;
  padding: 0 0 $spacingLg 0;

  @include size-above('md') {
    position: absolute;
    top: 0;
    bottom: 0;
    left: $menuDesktopWidth + $spacingXl + 1px;
    width: 624px;
    height: 100%;

    background-color: $n0;
    color: $n800;
    overflow-y: auto;

    padding: $spacingLg;
    border-bottom-right-radius: $spacingXs;
  }
}

.menuCategoryName {
  display: none;

  @include size-above('md') {
    display: block;
    text-decoration: none;

    width: 270px;
    margin: 0 $spacingLg $spacingLg 0;

    @include text('body2', 'bold');
    color: $n800;

    &:hover {
      cursor: text;
    }
  }
}

.menuSubCategories {
  margin: 0;
  padding: 0;
  list-style: none;

  @include size-above('md') {
    width: 270px;
  }
}

.menuSubCategoryItem {
  display: block;

  margin-top: $spacingMd;

  @include size-below('md') {
    margin-top: $spacingLg;
  }
}

.menuSubCategoryItemLink {
  border: 0;
  text-decoration: none;
  color: $n800;

  &:hover {
    text-decoration: underline;
  }

  @include size-below('md') {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    background-color: $b100;

    margin: 0;
    padding: $spacingXXs $spacingMd;

    width: 100%;

    @include text('body2', 'regular');

    min-height: 20px;
  }

  @include size-above('md') {
    @include text('small', 'semiBold');
  }
}

.menuSubCategoryWrapper {
  position: relative;
}

.seeMoreButton {
  position: absolute;
  bottom: 0;

  background-color: $n0;
  border: none;
  width: 270px;
  height: 35px;

  color: $n800;

  @include text('small', 'Bold');
  text-decoration: underline;
  text-align: left;
  padding: 0;

  &:hover {
    cursor: pointer;
  }

  :before {
    background: linear-gradient(to top, $n0, rgba(255, 255, 255, 0));
    width: 100%;
    height: 20px;
    position: absolute;
    content: '';
    bottom: 30px;
  }

  &[aria-hidden='true'] {
    display: none;
  }
}

.seeMoreButtonText {
  display: flex;
  align-items: center;
}

.seeMoreButtonArrow {
  height: 16px;
  width: 16px;
}

/****************************************************************************************************
 * .campaignsContainer: Menu section containing campaigns
 ****************************************************************************************************/

.campaignsContainer {
  display: none;

  @include size-above('md') {
    display: block;
    position: absolute;
    width: 280px;
    height: 312px;

    top: 0;
    right: 0;
  }
}

.campaigns {
  position: fixed;

  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  width: 280px;
  gap: $spacingMd;
}

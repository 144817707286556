@use '/src/domains/core/tamagoshiv2/tokens/colors';
@use '/src/domains/core/tamagoshiv2/tokens/radius';
@use '/src/domains/core/tamagoshiv2/tokens/spacings';
@import '@/core/tamagoshi/scss/mixins';
@import '@/core/tamagoshi/scss/zIndexes';

.container {
  @include size-above('md') {
    max-width: 1380px;
    margin-left: auto;
    margin-right: auto;

    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
  }
}

.main {
  width: 100%;
  max-height: 85vh;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  border-radius: spacings.$m spacings.$m 0 0;
  padding: spacings.$l spacings.$m spacings.$m;
  background-color: colors.$foundation-textIconInverted;
  z-index: $zIndexSticky;

  @include size-above('md') {
    bottom: unset;
    position: absolute;
    padding: spacings.$m;
    border-radius: 0 0 spacings.$m spacings.$m;
    max-height: calc(100vh - 210px);
    max-width: calc(100% - 48px);
  }
}

.close {
  display: none;

  @include size-above('md') {
    position: absolute;
    top: spacings.$m;
    right: 0;
    display: block;
    z-index: $zIndexSticky;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include size-above('md') {
    display: none;
  }
}

.closeMobile {
  border: 0;
  padding: spacings.$m;
  border-radius: 50%;
  color: colors.$foundation-textIconDisabled;
  background-color: transparent;

  &:hover {
    color: colors.$foundation-textIconPrimary;
    background-color: #fafafa; // stylelint-disable-line color-no-hex
  }
}

.list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  row-gap: spacings.$m;
  justify-items: center;
  flex-grow: 1;
  overflow-y: auto;
  padding: 0;
  list-style-type: none;
}

.linkWithModal {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.image {
  width: 280px;
  height: 280px;
  border-radius: radius.$container;
}

@import '@/core/tamagoshi/scss';

.root {
  position: relative;
  display: grid;
  padding: $spacingXs;
  max-width: 312px;
  min-width: 312px;
  grid-template-areas:
    'image title'
    'image price';
  background: $n0;
  border: 1px solid $n300;
  box-shadow: 0 2px 4px rgba(12, 25, 58, 0.04);
  border-radius: $defaultBorderRadius;
  text-decoration: none;
  color: $n800;

  .image {
    grid-area: image;
  }

  .brandImage {
    position: absolute;
    left: $spacingXs;
    bottom: $spacingXs;
  }

  .title,
  .price {
    margin-left: $spacingXs;
  }

  .title {
    grid-area: title;
    display: -webkit-box; // stylelint-disable value-no-vendor-prefix
    -webkit-box-orient: vertical; // stylelint-disable property-no-vendor-prefix
    -webkit-line-clamp: 2;
    overflow: hidden;
    max-height: 40px;
    @include text('body2', 'semiBold');
  }

  .price {
    grid-area: price;
    font-size: 24px;

    &.promo {
      color: $r600;
    }
  }

  @media (hover) {
    &:hover {
      box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.08);
    }
    &:focus {
      transition: none;

      &:not(:active) {
        box-shadow: 0 0 2px #12bbff; // stylelint-disable-line color-no-hex
        border: 1px solid #12bbff; // stylelint-disable-line color-no-hex
      }
    }
  }

  &.vertical {
    display: flex;
    width: 146px;
    height: 194px;
    max-width: 146px;
    min-width: 146px;
    flex-direction: column;

    .separator,
    .tag {
      height: 16px;
      margin-top: $spacingXXs;
      margin-bottom: 2px;
    }

    .price {
      @include text('body1');
      line-height: 16px;
    }

    .title {
      @include text('small', 'semiBold');
      align-self: center;
      margin-bottom: $spacingXXs;
      height: 33px;
      width: 100%;
    }

    .title,
    .price {
      margin-left: 0;
    }

    .brandImage {
      object-fit: contain;
      bottom: 0;
      top: 92px;
      &.vertical {
        height: 16px;
      }
    }

    .image {
      @include text('small', 'semiBold');
      align-self: center;
      &.vertical {
        height: 100px;
      }
    }
  }
}

@use '/src/domains/core/tamagoshiv2/tokens/colors';
@use '/src/domains/core/tamagoshiv2/tokens/spacings';
@use '/src/domains/core/tamagoshiv2/tokens/typography';

.root {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.info {
  font-size: typography.$fontSizeBody1;
  color: colors.$foundation-textIconDisabled;
}
.button {
  border: none;
  background: none;
  padding: 0;
}
.label {
  margin-right: spacings.$xs;
  white-space: nowrap;
  color: colors.$foundation-textIconDisabled;
}

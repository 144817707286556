@import '../../scss';
@import 'closeIcon';
@import 'footer';
@import 'header';
@import 'variables';

.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: $modalZIndex;
  font-family: $modalFontFamily;

  &:focus {
    outline: none;
  }
}

// TODO: define the animation for overlay
.overlay {
  background-color: rgba($n800, 0.65);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.body {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 16px 16px 0 0;
  padding: 0;
  @include clickable-bottom-element($spacingXl);
  min-height: 200px;
  max-height: calc(100% - 48px);
  background-color: $modalBgColor;
  width: 100%;

  &.withPadding {
    padding: $spacingLg $spacingMd $spacingMd;
  }
}

@include size-above('sm') {
  .container {
    align-items: center;
  }

  .body {
    width: unset;
    border-radius: $defaultBorderRadius;

    &.withPadding {
      padding: $spacingLg;
    }

    &.sm {
      &.withPadding {
        padding: $spacingMd;
      }
    }
  }

  .sm {
    min-width: 375px;
    max-width: 447px;
  }

  .md {
    min-width: 471px;
    max-width: 680px;
  }

  .lg {
    min-width: 569px;
    max-width: 912px;
    width: 80%;
    max-height: 96%;
  }

  .sm,
  .md {
    width: 47%;
    max-height: 84%;
  }

  .sm,
  .md,
  .lg {
    margin: $spacingLg;
    min-height: 208px;
  }
}

@include size-above('md') {
  .sm {
    width: 31%;
  }
}

@include size-above('lg') {
  .sm {
    width: 30%;
  }

  .lg {
    width: 63%;
  }
}

.fullWidth {
  width: 100%;
}

@import '@/core/tamagoshi/scss';
@import '@/core/tamagoshiv2/tokens/colors/_palette';
@import '@/domains/productDiscovery/HeaderLeonidas/styles/commons';
@import '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/components/Suggestions/Suggestions.module';

.root {
  position: relative;
  width: 100%;
  padding: 0;
  flex: 1 1 auto;
  display: block;
}

.suggestions {
  height: calc(100% - $spacingMd);

  @include size-above('md') {
    height: calc($desktopLeftColumnHeight - 20px);
    margin-top: $spacingXs;

    &.emptyState {
      height: calc(
        $desktopLeftColumnHeight - $desktopProductButtonHeight - $spacingXs
      );
    }
  }
}

.productsSuggestions {
  display: none;
  @include size-above('md') {
    display: block;
  }

  .titleSkeleton {
    height: 28px;
    margin: 0 2px;
  }

  .productsSkeleton {
    @include size-above('md') {
      margin-bottom: $spacingXs;
    }
  }
}

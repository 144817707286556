@import '@/core/tamagoshi/scss';

.menuBackButton {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0;

  cursor: pointer;

  width: 40px;
  height: 40px;

  color: inherit;
  background: none;
  border: 0;
  border-radius: 0;

  @include size-below('md') {
    margin: $spacingXs;
  }
}

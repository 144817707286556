.close.close {
  display: flex;
  position: absolute;
  right: 16px;
  top: 16px;
  color: $n800;
  text-decoration: none;
  padding: 0;
  outline: none;
  border: none;
}

@include size-above('sm') {
  .close {
    right: 18px;
    top: 20px;
  }
}

@import '@/core/tamagoshi/scss';

.adviceLink {
  @include size-above('md') {
    max-height: 40px;
    min-height: 40px;
  }
}

.adviceLink:not(#important) {
  &:before {
    content: '';
    display: block;
    width: $spacingLg;
    height: $spacingLg;
    margin-right: $spacingXs;
    background: url('assets/icon.svg') no-repeat center center;
  }
}

@use '/src/domains/core/tamagoshiv2/tokens/radius';

.root {
  border-radius: radius.$container;
  box-shadow: 0 4px 8px rgba(12, 25, 58, 0.24);
  cursor: pointer;
  position: relative;
  width: 280px;
  height: 280px;
}

@use '/src/domains/core/tamagoshiv2/tokens/colors';
@use '/src/domains/core/tamagoshiv2/tokens/spacings';

@import '@/core/tamagoshi/scss';
@import '@/domains/productDiscovery/HeaderLeonidas/styles/commons';

.menuButton {
  --padding-tb: #{spacings.$s};
  --padding-lr: #{spacings.$s};

  --main-color: #{colors.$foundation-textIconInverted};
  --background-color: #{colors.$accent-backgroundPrimary};

  --menu-button-width: 40px;
  --menu-button-min-height: 44px;

  display: block;

  padding: 0;
  margin: 0;

  cursor: pointer;

  background: none;
  border: 0;
  color: var(--main-color);

  -webkit-tap-highlight-color: transparent;

  max-width: var(--menu-button-width);
  min-height: var(--menu-button-min-height);

  @include text('xsmall', 'semiBold');

  @include size-above('md') {
    --padding-lr: 6px;
    --menu-button-width: 92px;

    @include text('body2', 'semiBold');

    padding: spacings.$s spacings.$s 6px spacings.$s;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    position: relative;
    column-gap: spacings.$xs;
    margin-right: spacings.$s;

    @include buttonTabDefault;

    &:hover,
    &:focus {
      @include buttonTabHover;
    }
  }

  svg {
    margin-top: -$spacingXs;
    margin-bottom: -$spacingXs;
  }

  &[data-menu-visible='true'],
  svg {
    fill: currentcolor;
  }

  span {
    @include size-below('md') {
      display: none;
    }
  }
}

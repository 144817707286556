@import '@/core/tamagoshi/scss';

.modal {
  @include size-above('md') {
    width: 536px;
  }

  button {
    &[aria-label='close'] {
      right: $spacingMd;
      top: $spacingMd;
    }
  }
}

.header {
  margin-bottom: 0;
}

.title {
  color: $n800;
  @include text('h4', 'bold');
}

.body {
  @include text('body2', 'semiBold');
  color: $n800;
  margin-left: 128px;
  min-height: 84px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.couponText {
  font-size: 12px;
  font-weight: 400;
  color: $n800;
  margin-top: $spacingMd;
}

.content {
  padding: $spacingLg 0;
  position: relative;

  @include size-below('md') {
    padding: $spacingLg 0;
  }

  &:before {
    height: 112px;
    width: 112px;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;

    @include size-below('md') {
      top: $spacingSm;
    }
  }

  &.b2c {
    &:before {
      background: url('/assets/img/themes/default/svg/newsletter/newsletter-b2c.svg')
        no-repeat;
      background-size: cover;
    }
  }

  &.b2b {
    &:before {
      background: url('/assets/img/themes/default/svg/newsletter/newsletter-b2b.svg')
        no-repeat;
      background-size: cover;
    }
  }
}

@import '@/core/tamagoshi/scss';

.navbar {
  &:before,
  &:after {
    content: '';
    width: 48px;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 10;
    pointer-events: none;
    transition: opacity 0.25s ease-out;

    @include size-below('md') {
      width: 24px;
    }
  }

  &:before {
    left: 0;
    background: linear-gradient(to right, $b700, rgba($b700, 0));
  }

  &:after {
    right: 0;
    background: linear-gradient(to left, $b700, rgba($b700, 0));
  }
}

@import '@/core/tamagoshi/scss';
@import '@/domains/productDiscovery/HeaderLeonidas/modules/searchBar/components/Suggestions/Suggestions.module';

$mobileProductsSliderHeight: 250px;

.emptyLeftSuggestionsContainer {
  padding: 0 $spacingLg;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  flex: 1;

  @include size-above('md') {
    height: calc($desktopLeftColumnHeight - $spacingXXs);
  }

  .title {
    color: $n800;
    margin: 0;
  }

  .subtitle {
    color: $n700;
    margin: 0;
  }
}

@import '@/core/tamagoshi/scss';

.root {
  .products {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: $spacingMd;
    column-gap: $spacingMd;
    margin-top: $spacingXs;
  }
}

.seeMoreButton {
  margin-top: $spacingMd;
  display: flex;
  justify-content: flex-end;
}

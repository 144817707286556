@import '@/core/tamagoshi/scss';
@import '../../styles/commons';

.menuCloseButton {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0;

  cursor: pointer;

  width: 40px;
  height: 40px;

  color: inherit;
  background-color: $n0;
  border: 1px solid $n0;
  border-radius: 50%;

  transition:
    color 0.15s ease-out,
    background-color 0.15s ease-out,
    border-color 0.15s ease-out;

  &:hover {
    color: $n800;
    background-color: $n0;
    border-color: $n800;
  }

  &:active {
    color: $n800;
    background-color: $n300;
    border-color: $n800;
  }

  &:focus:not(:active) {
    color: $n800;
    background-color: $n0;
    border-color: $focusBorderColor;
  }

  @include size-below('md') {
    margin: $spacingXs;
    color: inherit;
    background-color: $b700;
    border: 1px solid $b700;
  }

  @include size-above('md') {
    width: $spacingXl;
    height: $spacingXl;
  }
}

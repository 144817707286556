@import '@/core/tamagoshi/scss';
@import '../../scss/variables';
@import '@/core/tamagoshiv2/tokens/colors/_palette';

.product {
  > :last-child {
    > span:nth-child(2) {
      @include text('small');
    }
  }
}

.root {
  width: calc(100% + #{$spacingMd} * 2);
  margin-left: -$spacingMd;
  margin-right: -$spacingMd;
  @include size-below('md') {
    /* Override grid template of Carousel component with horizontalPadding prop */
    grid-template-columns: 1fr auto !important; /* stylelint-disable-line declaration-no-important */
  }

  .slider {
    padding: $spacingXs 0 $spacingSm;
    margin-top: $spacingXs;
    padding-bottom: 0;

    &.favorites {
      align-items: flex-end;
      column-gap: $spacingXs;
    }
  }

  .sliderControls {
    @include size-below('md') {
      display: none;
    }
  }

  .product:not(:first-child) {
    margin-left: $spacingXs;
  }
}

.favorites > div {
  align-items: flex-end;
}

.seeMoreButton {
  padding: $spacingLg $spacingMd;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: $Sq000;
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: $spacingMd;
  color: $n800;
}

@include size-above('sm') {
  .footer {
    margin-top: $spacingLg;
  }
}

@include size-above('md') {
  .lg {
    .footer {
      margin-top: $spacingXl;
    }
  }
}

// z-indexes are normalized at ManoMano
// More info in https://manomano.atlassian.net/wiki/spaces/TGI/pages/3090908305/Tamagoshi+z-index+official+documentation+2021+07+13
$zIndexMandatoryAction: 100 !default;
$zIndexClickTriggeredContent: 90 !default;
$zIndexSticky: 80 !default;
$zIndexMouseHoverContent: 70 !default;
$zIndexSuggestedSupport: 60 !default;
$zIndexPrimaryAction: 50 !default;
$zIndexSecondaryAction: 40 !default;
$zIndexAutoTriggeredModal: 30 !default;

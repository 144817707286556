@use '/src/domains/core/tamagoshiv2/tokens/colors';
@use '/src/domains/core/tamagoshiv2/tokens/spacings';
@import '@/core/tamagoshi/scss';
@import '@/domains/productDiscovery/HeaderLeonidas/styles/commons';

.mainBar {
  background-color: colors.$brand-backgroundPrimaryActive;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);

  &[data-overlay='overlay-menu'] {
    position: relative;
    z-index: $headerLeonidasZIndexMenu;
  }

  @include size-above('md') {
    &[data-overlay='overlay-promos'] {
      position: relative;
      z-index: $headerLeonidasZIndexMenu;
    }
  }
}

.mainBarContent {
  max-width: 1424px; // 1376px + 24px + 24px
  margin-left: auto;
  margin-right: auto;

  display: grid;

  grid-template-areas:
    'button logo navigation navigation'
    'search search search search'
    'links  links  links  links';
  grid-template-columns: auto auto 1fr auto;

  @include size-above('md') {
    grid-template-areas:
      'logo   logo  search  search navigation navigation'
      'button links links  links links  banner';
    grid-template-columns: auto auto auto 1fr auto auto;
    position: relative;
  }
}

.logo {
  grid-area: logo;
}

.menuButton {
  grid-area: button;
  margin-left: spacings.$m;
}

.search {
  grid-area: search;

  @include size-below('md') {
    margin: spacings.$s spacings.$m $spacingSm spacings.$m;
  }

  @include size-above('md') {
    margin-top: spacings.$s;
    margin-bottom: spacings.$xs;
    min-height: 60px;
  }
}

.navigation {
  grid-area: navigation;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;

  margin: spacings.$xs spacings.$m spacings.$xs 0;

  @include size-above('sm') {
    gap: 8px;
  }

  @include size-above('md') {
    margin: $spacingSm spacings.$l $spacingSm spacings.$m;
  }
}

.proButton {
  @include size-below('sm') {
    display: none;
  }
}

.account {
  @include size-above('md') {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
}

.navbar {
  grid-area: links;

  @include size-below('md') {
    min-height: spacings.$xl;
  }

  @include size-above('md') {
    margin-right: spacings.$l;
  }
}

.headerBanner {
  grid-area: banner;
  justify-self: flex-end;
  margin-left: spacings.$s;
  margin-right: spacings.$l;
  width: 204px;
  height: 34px;

  @include size-below('md') {
    display: none;
  }
}

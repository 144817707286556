@import '@/core/tamagoshi/scss';

.container {
  position: relative;
  width: 132px;
  height: 280px;
}

.img {
  display: block;
  /* stylelint-disable declaration-no-important */
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  /* stylelint-enable declaration-no-important */
}

.itemLink {
  border: none;
  cursor: pointer;
  padding: 0;
}

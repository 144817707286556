@use '/src/domains/core/tamagoshiv2/tokens/colors';

@import '@/core/tamagoshi/scss';
@import '../../styles/commons';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  min-width: 40px;
  text-decoration: none;
  position: relative;

  @include buttonDefault;
  color: colors.$foundation-textIconInverted;

  &:hover {
    @include buttonHover;
  }

  &:focus {
    @include buttonFocus;
  }

  &:active {
    @include buttonPressed;
  }

  &:disabled {
    @include buttonDisabled;
  }
}

.basketBadgeCount {
  @include text('small', 'bold');
  color: colors.$foundation-textIconPrimary;

  background: colors.$accent-backgroundPrimary;
  position: absolute;
  right: 2px;
  top: -3px;

  border-radius: $spacingLg;

  align-content: center;
  line-height: 18px;
  height: 22px;
  width: 22px;
  text-align: center;
}

.add {
  @include text('tiny', 'bold');
}

.cartImg {
  height: 26px;
  width: 26px;
}
